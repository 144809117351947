<template>
  <div id="kt_bside" class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page" style="background-position: center bottom; background-repeat: repeat; background-size: 350px; background-image: url(media/svg/humans/bg-aside2.svg)">

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid">
        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div class="container">
              
                <!-- set progressbar -->
                <vue-progress-bar></vue-progress-bar>
              
                <!--begin::Profile Account Information-->
                <div class="d-flex flex-row">
                    <!--begin::Aside-->
                    <div class="bside flex-row-auto offcanvas-mobile w-300px w-xxl-350px" id="kt_verifikasi_aside" ref="kt_verifikasi_aside">
                        <!--begin::Profile Card-->
                        <div class="card border-0 position-sticky top-0" id="kt_page_sticky_card">
                            <!--begin::Body-->
                            <div class="card-body border-lg border-0 border-primary rounded mt-5 pt-4">
                                <!--begin::Nav-->
                                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                                    <div
                                    class="aside-menu-wrapper flex-column-fluid"
                                    >
                                        <!--begin::Menu Container-->
                                        <div
                                            class="aside-menu"
                                            data-menu-vertical="1"
                                            data-menu-scroll="1"
                                        >
                                            <perfect-scrollbar
                                            class="aside-menu scroll"
                                            style="max-height: 90vh; position: relative;"
                                            >
                                                <ul class="menu-nav">
                                                    <div class="navi-item mb-2">
                                                        <span class="badge badge-primary font-weight-bold my-2 mr-3">
                                                            <template v-if="currentUser.akses.tipe_name">Akses Tipe {{ currentUser.akses.tipe_name || "" }}</template>
                                                        </span>
                                                    </div>
                                                    <div class="navi-item mb-2">
                                                        <!--begin::Item-->
                                                        <router-link
                                                        to="/dashboard"
                                                        v-slot="{ href, navigate, isActive, isExactActive }"
                                                        >
                                                            <a
                                                            class="navi-link p-4 my-2"
                                                            :class="[
                                                                isActive && 'active',
                                                                isExactActive && 'active'
                                                            ]"
                                                            :href="href" @click="navigate"
                                                            >
                                                                <span class="navi-icon mr-2">
                                                                    <span class="svg-icon svg-icon-sm">
                                                                        <!--begin::Svg Icon-->
                                                                        <inline-svg src="media/svg/icons/Layout/Layout-top-panel-2.svg" />
                                                                        <!--end::Svg Icon-->
                                                                    </span>
                                                                </span>
                                                                <span class="navi-text font-size-lg">
                                                                    Dashboard
                                                                </span>
                                                            </a>
                                                        </router-link>
                                                        <!--end::Item-->
                                                    </div>
                                                    
                                                    <li
                                                    aria-haspopup="true"
                                                    data-menu-toggle="hover"
                                                    class="navi-item menu-item menu-item-submenu mb-2"
                                                    v-bind:class="{ 'menu-item-open': hasActiveChildren('/verifikasi/nasional') }"
                                                    >
                                                        <a 
                                                        class="navi-link menu-link menu-toggle p-4"
                                                        @click="setActiveMenu"
                                                        >
                                                            <span class="navi-icon align-self-center mr-2">
                                                                <span class="svg-icon svg-icon-sm">
                                                                    <!--begin::Svg Icon-->
                                                                    <inline-svg src="media/svg/project/kementan.svg" />
                                                                    <!--end::Svg Icon-->
                                                                </span>
                                                            </span>
                                                            <span class="navi-text menu-text font-size-lg">
                                                                Perunggasan Nasional
                                                            </span>
                                                            <span v-if="totalNotif('nasional') > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                {{ this.totalNotif('nasional') }}
                                                            </span>
                                                            <i class="menu-arrow"></i>
                                                        </a>
                                                        <div class="menu-submenu">
                                                            <span class="menu-arrow"></span>
                                                            <ul class="menu-subnav">
                                                                <li aria-haspopup="true" class="menu-item menu-item-parent">
                                                                    <span class="menu-link">
                                                                    <span class="menu-text">Perunggasan Nasional</span>
                                                                    </span>
                                                                </li>

                                                                <div class="navi-item ml-8 mt-2 mb-2">
                                                                    <li
                                                                    aria-haspopup="true"
                                                                    data-menu-toggle="hover"
                                                                    class="navi-item menu-item menu-item-submenu mb-2"
                                                                    v-bind:class="{ 'menu-item-open': hasActiveChildren('/verifikasi/nasional/populasi') }"
                                                                    >
                                                                        <a 
                                                                        class="navi-link menu-link menu-toggle p-4"
                                                                        @click="setActiveMenu"
                                                                        >
                                                                            <span class="navi-icon align-self-center mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Home/Stairs.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text menu-text font-size-lg">
                                                                                Populasi
                                                                            </span>
                                                                            <span v-if="totalNotif(['nasional', 'populasi'], 2) > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ this.totalNotif(['nasional', 'populasi'], 2) }}
                                                                            </span>
                                                                            <i class="menu-arrow"></i>
                                                                        </a>
                                                                        <div class="menu-submenu">
                                                                            <span class="menu-arrow"></span>
                                                                            <ul class="menu-subnav">
                                                                                <li aria-haspopup="true" class="menu-item menu-item-parent">
                                                                                    <span class="menu-link">
                                                                                    <span class="menu-text">Populasi</span>
                                                                                    </span>
                                                                                </li>

                                                                                <div class="navi-item ml-8 mt-2 mb-2">
                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/populasi/chickin"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Chickin
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.populasi.chickin > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.populasi.chickin }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->

                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/populasi/deplesi"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Deplesi
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.populasi.deplesi > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.populasi.deplesi }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->

                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/populasi/feedintake"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Feed Intake
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.populasi.feedintake > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.populasi.feedintake }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->

                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/populasi/transfer"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Transfer
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.populasi.transfer > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.populasi.transfer }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->

                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/populasi/produksi-egg"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Produksi Egg
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.populasi.produksi_egg > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.populasi.produksi_egg }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->

                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/populasi/afkir"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Afkir
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.populasi.afkir > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.populasi.afkir }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </li>

                                                                    <li
                                                                    aria-haspopup="true"
                                                                    data-menu-toggle="hover"
                                                                    class="navi-item menu-item menu-item-submenu mb-2"
                                                                    v-bind:class="{ 'menu-item-open': hasActiveChildren('/verifikasi/nasional/produksi') }"
                                                                    >
                                                                        <a 
                                                                        class="navi-link menu-link menu-toggle p-4"
                                                                        @click="setActiveMenu"
                                                                        >
                                                                            <span class="navi-icon align-self-center mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Home/Home.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text menu-text font-size-lg">
                                                                                Produksi
                                                                            </span>
                                                                            <span v-if="totalNotif(['nasional', 'produksi'], 2) > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ this.totalNotif(['nasional', 'produksi'], 2) }}
                                                                            </span>
                                                                            <i class="menu-arrow"></i>
                                                                        </a>
                                                                        <div class="menu-submenu">
                                                                            <span class="menu-arrow"></span>
                                                                            <ul class="menu-subnav">
                                                                                <li aria-haspopup="true" class="menu-item menu-item-parent">
                                                                                    <span class="menu-link">
                                                                                    <span class="menu-text">Produksi</span>
                                                                                    </span>
                                                                                </li>

                                                                                <div class="navi-item ml-8 mt-2 mb-2">
                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/produksi/setting-he"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Setting HE
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.produksi.setting_he > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.produksi.setting_he }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->

                                                                                    <!--begin::Item-->
                                                                                    <router-link
                                                                                    to="/verifikasi/nasional/produksi/pullchick"
                                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                                    >
                                                                                        <a
                                                                                        class="navi-link p-4 my-2"
                                                                                        :class="[
                                                                                            isActive && 'active',
                                                                                            isExactActive && 'active'
                                                                                        ]"
                                                                                        :href="href" @click="navigate"
                                                                                        >
                                                                                            <span class="navi-icon mr-2">
                                                                                                <span class="svg-icon svg-icon-sm">
                                                                                                    <!--begin::Svg Icon-->
                                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                                    <!--end::Svg Icon-->
                                                                                                </span>
                                                                                            </span>
                                                                                            <span class="navi-text font-size-lg">
                                                                                                Pullchick
                                                                                            </span>
                                                                                            <span v-if="currentCount.nasional.produksi.pullchick > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                                {{ currentCount.nasional.produksi.pullchick }}
                                                                                            </span>
                                                                                        </a>
                                                                                    </router-link>
                                                                                    <!--end::Item-->
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </li>

                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/nasional/distribusi/send-doc"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Shopping/Box2.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Distribusi
                                                                            </span>
                                                                            <span v-if="currentCount.nasional.distribusi.send_doc > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.nasional.distribusi.send_doc }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li
                                                    aria-haspopup="true"
                                                    data-menu-toggle="hover"
                                                    class="navi-item menu-item menu-item-submenu mb-2"
                                                    v-bind:class="{ 'menu-item-open': hasActiveChildren('/verifikasi/project') }"
                                                    >
                                                        <a 
                                                        class="navi-link menu-link menu-toggle p-4"
                                                        @click="setActiveMenu"
                                                        >
                                                            <span class="navi-icon align-self-center mr-2">
                                                                <span class="svg-icon svg-icon-sm">
                                                                    <!--begin::Svg Icon-->
                                                                    <inline-svg src="media/svg/icons/Design/Layers.svg" />
                                                                    <!--end::Svg Icon-->
                                                                </span>
                                                            </span>
                                                            <span class="navi-text menu-text font-size-lg">
                                                                Project
                                                            </span>
                                                            <span v-if="totalNotif('project') > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                {{ this.totalNotif('project') }}
                                                            </span>
                                                            <i class="menu-arrow"></i>
                                                        </a>
                                                        <div class="menu-submenu">
                                                            <span class="menu-arrow"></span>
                                                            <ul class="menu-subnav">
                                                                <li aria-haspopup="true" class="menu-item menu-item-parent">
                                                                    <span class="menu-link">
                                                                        <span class="menu-text">Project</span>
                                                                    </span>
                                                                </li>

                                                                <div class="navi-item ml-8 mt-2 mb-2">
                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/project/cutting-he"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/project/eggs.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Cutting HE
                                                                            </span>
                                                                            <span v-if="currentCount.project.cuttinghe > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.project.cuttinghe }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->

                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/project/afkir-dini"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/project/hen.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Afkir Dini
                                                                            </span>
                                                                            <span v-if="currentCount.project.afkirdini > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.project.afkirdini }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->

                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li
                                                    aria-haspopup="true"
                                                    data-menu-toggle="hover"
                                                    class="navi-item menu-item menu-item-submenu mb-2"
                                                    v-bind:class="{ 'menu-item-open': hasActiveChildren('/verifikasi/master-data') }"
                                                    >
                                                        <a 
                                                        class="navi-link menu-link menu-toggle p-4"
                                                        @click="setActiveMenu"
                                                        >
                                                            <span class="navi-icon align-self-center mr-2">
                                                                <span class="svg-icon svg-icon-sm">
                                                                    <!--begin::Svg Icon-->
                                                                    <inline-svg src="media/svg/icons/Home/Library.svg" />
                                                                    <!--end::Svg Icon-->
                                                                </span>
                                                            </span>
                                                            <span class="navi-text menu-text font-size-lg">
                                                                Master
                                                            </span>
                                                            <span v-if="totalNotif('master') > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                {{ this.totalNotif('master') }}
                                                            </span>
                                                            <i class="menu-arrow"></i>
                                                        </a>
                                                        <div class="menu-submenu">
                                                            <span class="menu-arrow"></span>
                                                            <ul class="menu-subnav">
                                                                <li aria-haspopup="true" class="menu-item menu-item-parent">
                                                                    <span class="menu-link">
                                                                    <span class="menu-text">Master</span>
                                                                    </span>
                                                                </li>

                                                                <div class="navi-item ml-8 mt-2 mb-2">
                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/master-data/farm"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Farm
                                                                            </span>
                                                                            <span v-if="currentCount.master.farm > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.master.farm }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->

                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/master-data/hatchery"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Hatchery
                                                                            </span>
                                                                            <span v-if="currentCount.master.hatchery > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.master.hatchery }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->

                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/master-data/surat-edaran"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Surat Edaran
                                                                            </span>
                                                                            <span v-if="currentCount.master.suratedaran > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.master.suratedaran }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->

                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/master-data/peternak"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Peternak
                                                                            </span>
                                                                            <span v-if="currentCount.master.peternak > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.master.peternak }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->

                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/master-data/user"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                User
                                                                            </span>
                                                                            <span v-if="currentCount.master.user > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.master.user }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->

                                                                    <!--begin::Item-->
                                                                    <router-link
                                                                    to="/verifikasi/master-data/role"
                                                                    v-slot="{ href, navigate, isActive, isExactActive }"
                                                                    >
                                                                        <a
                                                                        class="navi-link p-4 my-2"
                                                                        :class="[
                                                                            isActive && 'active',
                                                                            isExactActive && 'active'
                                                                        ]"
                                                                        :href="href" @click="navigate"
                                                                        >
                                                                            <span class="navi-icon mr-2">
                                                                                <span class="svg-icon svg-icon-sm">
                                                                                    <!--begin::Svg Icon-->
                                                                                    <inline-svg src="media/svg/icons/Shopping/MC.svg" />
                                                                                    <!--end::Svg Icon-->
                                                                                </span>
                                                                            </span>
                                                                            <span class="navi-text font-size-lg">
                                                                                Role
                                                                            </span>
                                                                            <span v-if="currentCount.master.role > 0" class="label label-md label-light-primary label-rounded font-weight-bolder right-0 mt-1 mr-1">
                                                                                {{ currentCount.master.role }}
                                                                            </span>
                                                                        </a>
                                                                    </router-link>
                                                                    <!--end::Item-->
                                                                </div>                                                            
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </perfect-scrollbar>
                                        </div>
                                    </div>

                                </div>
                                <!--end::Nav-->
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::Profile Card-->
                    </div>
                    <!--end::Aside-->

                    <!--begin::Content-->
                    <div class="flex-row-fluid mt-22 mt-lg-5 ml-lg-8">
                        <transition name="fade-in-up">
                            <router-view />
                        </transition>
                    </div>
                    <!--end::Content-->
                </div>
                
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_COUNT } from "@/core/services/store/verification.module";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTHeaderMobile,
    KTFooter,
    KTScrollTop,
    Loader
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "auth-login" });
    }

    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }

    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_verifikasi_aside"]);
    });

    this.$store.dispatch(GET_COUNT);

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    totalNotif(key, level = 1) {
        let sum = 0;
        let obj            
        if (level == 2) {
            obj = this.currentCount[key[0]][key[1]]
        }
        else if (level == 3) {
            obj = this.currentCount[key[0]][key[1]][key[2]]
        }
        else {
            obj = this.currentCount[key]
        }

        for( let el in obj ) {
            if( Object.prototype.hasOwnProperty.call(obj, el) ) {
                if(typeof obj[el] === 'object' && obj[el] !== null){

                    let obj2 = obj[el]
                    for( let el2 in obj2 ) {
                        if( Object.prototype.hasOwnProperty.call(obj2, el2) ) {
                            if(typeof obj2[el2] === 'object' && obj2[el2] !== null) {
                                
                                let obj3 = obj2[el2]
                                for( let el3 in obj3 ) {        
                                    if( Object.prototype.hasOwnProperty.call(obj3, el3) ) {
                                        sum += parseFloat( obj3[el3] );
                                    }
                                    else {
                                        sum += parseFloat( obj3 );
                                    }
                                }
                            }
                            else{
                                sum += parseFloat( obj2[el2] );
                            }
                        }
                        else{
                            sum += parseFloat( obj2 );
                        }
                    }
                }
                else {
                    sum += parseFloat( obj[el] );
                }
            }        
            else{
                sum += parseFloat( obj );
            }
        }
        return sum;
    },

    setActiveMenu(event) {
        let target = event.target;
        if (!event.target.classList.contains("menu-toggle")) {
            target = event.target.closest(".menu-toggle");
        }

        const li = target.closest('.menu-item');
        const submenu = KTUtil.child(li, '.menu-submenu');

        if ( li && submenu ) {
            event.preventDefault();
            const speed = 400;

            if ( KTUtil.hasClass(li, 'menu-item-open') === false ) {
                KTUtil.slideDown(submenu, speed);
                KTUtil.addClass(li, 'menu-item-open');

            } else {
                KTUtil.slideUp(submenu, speed);
                KTUtil.removeClass(li, 'menu-item-open');
            }
        }

    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "currentUser",
      "currentCount"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>

<style lang="scss">
    #kt_bside {
        background: #fff;
    }
</style>